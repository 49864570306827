
import React from 'react';
import { useThemeContext } from "../../hooks/usethemeContext";
import { NavLink } from 'react-router-dom';
import { Icon } from '@iconify/react';
import logo from "../../assets/images/logo.webp";
import { Col, Container, Row } from 'react-bootstrap';
import { switchTheme } from '../../assets/styles';

const Footer = () => {


    const { darkMode } = useThemeContext();
    const { iconStyle, borderTop } = switchTheme(darkMode);
    const currentYear = new Date().getFullYear();
    const lg = 6, md = 12;
    const style = {color: '#008080'};
    
    return <>
        <div className={`footer-area pt-3 pb-1`} style={borderTop}>
            <div>
                <Container>
                    <Row className="align-items-center">
                        <Col md={md} lg={lg}>
                            <div>
                                <NavLink to="/">
                                    <img src={logo} width={"23%"} height={"23%"}alt="logo" />
                                </NavLink>
                                <p>
                                    Let Us <strong><span style={style} >Help You Grow</span></strong> 
                                    &nbsp;Your Business <strong><span style={style} >By Teaching You</span></strong> How To 
                                    <strong><span style={style} >&nbsp;Automate </span></strong>  Your
                                    <strong><span style={style} >&nbsp;Marketing, Customer Capture, And Sales</span></strong> 
                                </p>
                            </div>
                        </Col>

                        <Col md={md} lg={lg}>
                           <div>
                                <span className='mr-5'>
                                    <a className='' href="" target="_blank" rel='noreferrer'>
                                        <Icon icon="iconoir:facebook-squared" style={ iconStyle }/>
                                    </a>
                                </span>
                                <span className='mx-3'>
                                    <a href="" target="_blank" rel='noreferrer'>
                                        <Icon icon="ph:instagram-logo" style={ iconStyle }/>
                                    </a>
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            
            <div className="footer-copy-right">
                <Container>
                    <p>
                        Copyright &copy;{currentYear} Metaverse Group. Designed by&nbsp;
                        <a className='text-warning' href="https://metaversegroup.tech/contact" target="_blank" rel='noreferrer'>
                            Analitico
                        </a>
                    </p>
                </Container>
            </div>
        </div>
    </>;
}

export default Footer;
