
import { useState } from "react";
import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from '../../assets/styles';
import { advantagesText } from "./helpers/advantagesText";
import CalendlyButton from "./buttons/CalendlyButton";
import { Col, Container, Row } from "react-bootstrap";
import FunFacts from "./FunFacts";
import { motion } from "framer-motion";
import VisibilitySensor from "react-visibility-sensor";

const Advantages = (props) => {
    const { darkMode } = useThemeContext();
    const { backgroundColor, advantagesIconColor } = switchTheme(darkMode);

    const sm = 6, lg = 6;
    let altText = advantagesText.taxClient;
    if (props.page === 'software') altText = advantagesText.taxPartner;

    const [isVisible, setIsVisible] = useState(false);
    const duration = 1.0;

    const AdvantagesComponent = () => {
        return (
            <div>
                <Container className="pt-5 pb-3">
                    {altText.heading1}
                </Container>
                <div className='mb-5 pb-5'>
                    <FunFacts />
                </div>
                <Container>
                    <div className="justify-items-center">
                        <Row className="align-items-center pb-">
                            <Col lg={lg}>
                                <Row>
                                    <Col lg={lg} sm={sm}>
                                        <VisibilitySensor
                                            onChange={(isVisible) => {
                                                if (!isVisible) {
                                                    setIsVisible(false);
                                                }
                                            }}
                                            partialVisibility={true}
                                            offset={{ top: 100 }}
                                        >
                                            <motion.div
                                                className={`${isVisible ? 'visible' : 'hidden'} box-1 single-services-box ${backgroundColor}`}
                                                style={advantagesIconColor}
                                                initial={{ opacity: 0, y: -125 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                transition={{ duration: duration + .3 }}
                                            >
                                                <i className="bi bi-database-lock"></i>
                                                {altText.box1}
                                            </motion.div>
                                        </VisibilitySensor>
                                    </Col>

                                    <Col lg={lg} sm={sm}>
                                        <VisibilitySensor
                                            onChange={(isVisible) => {
                                                if (!isVisible) {
                                                    setIsVisible(false);
                                                }
                                            }}
                                            partialVisibility={true}
                                            offset={{ top: 100 }}
                                        >
                                            <motion.div
                                                className={`${isVisible ? 'visible' : 'hidden'} box-2 single-services-box ${backgroundColor}`}
                                                style={advantagesIconColor}
                                                initial={{ opacity: 0, y: -125 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                transition={{ duration: duration + .5 }}
                                            >
                                                <i className="bi bi-pencil-square"></i>
                                                {altText.box2}
                                            </motion.div>
                                        </VisibilitySensor>
                                    </Col>
                                </Row>
                            </Col>

                            <Col lg={lg}>
                                <Row>
                                    <Col lg={lg} sm={sm}>
                                        <VisibilitySensor
                                            onChange={(isVisible) => {
                                                if (!isVisible) {
                                                    setIsVisible(false);
                                                }
                                            }}
                                            partialVisibility={true}
                                            offset={{ top: 100 }}
                                        >
                                            <motion.div
                                                className={`${isVisible ? 'visible' : 'hidden'} box-3 single-services-box ${backgroundColor}`}
                                                style={advantagesIconColor}
                                                initial={{ opacity: 0, y: -125 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                transition={{ duration: duration + .7 }}
                                            >
                                                <i className="bi bi-cloud-upload"></i>
                                                {altText.box3}
                                            </motion.div>
                                        </VisibilitySensor>
                                    </Col>

                                    <Col lg={lg} sm={sm}>
                                        <VisibilitySensor
                                            onChange={(isVisible) => {
                                                if (!isVisible) {
                                                    setIsVisible(false);
                                                }
                                            }}
                                            partialVisibility={true}
                                            offset={{ top: 100 }}
                                        >
                                            <motion.div
                                                className={`${isVisible ? 'visible' : 'hidden'} box-4 single-services-box ${backgroundColor}`}
                                                style={advantagesIconColor}
                                                initial={{ opacity: 0, y: -125 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                transition={{ duration: duration + .9 }}
                                            >
                                                <i className="bi bi-people"></i>
                                                {altText.box4}
                                            </motion.div>
                                        </VisibilitySensor>
                                    </Col>
                                </Row>
                            </Col>
                            {props.page === 'clients' ?
                                <>
                                    <Col lg={lg}>
                                        <Row>
                                            <Col lg={lg} sm={sm}>
                                                <VisibilitySensor
                                                    onChange={(isVisible) => {
                                                        if (!isVisible) {
                                                            setIsVisible(false);
                                                        }
                                                    }}
                                                    partialVisibility={true}
                                                    offset={{ top: 100 }}
                                                >
                                                    <motion.div
                                                        className={`${isVisible ? 'visible' : 'hidden'} box-5 single-services-box ${backgroundColor}`}
                                                        style={advantagesIconColor}
                                                        initial={{ opacity: 0, y: -125 }}
                                                        animate={{ opacity: 1, y: 0 }}
                                                        transition={{ duration: duration + 1.1 }}
                                                    >
                                                        <i className="bi bi-code-square"></i>
                                                        {altText.box5}
                                                    </motion.div>
                                                </VisibilitySensor>
                                            </Col>

                                            <Col lg={lg} sm={sm}>
                                                <VisibilitySensor
                                                    onChange={(isVisible) => {
                                                        if (!isVisible) {
                                                            setIsVisible(false);
                                                        }
                                                    }}
                                                    partialVisibility={true}
                                                    offset={{ top: 100 }}
                                                >
                                                    <motion.div
                                                        className={`${isVisible ? 'visible' : 'hidden'} box-6 single-services-box ${backgroundColor}`}
                                                        style={advantagesIconColor}
                                                        initial={{ opacity: 0, y: -125 }}
                                                        animate={{ opacity: 1, y: 0 }}
                                                        transition={{ duration: duration + 1.3 }}
                                                    >
                                                        <i className="bi bi-bank2"></i>
                                                        {altText.box6}
                                                    </motion.div>
                                                </VisibilitySensor>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <div className="col-lg-6">
                                        <Row>
                                            <Col lg={lg} sm={sm}>
                                                <VisibilitySensor
                                                    onChange={(isVisible) => {
                                                        if (!isVisible) {
                                                            setIsVisible(false);
                                                        }
                                                    }}
                                                    partialVisibility={true}
                                                    offset={{ top: 100 }}
                                                >
                                                    <motion.div
                                                        className={`${isVisible ? 'visible' : 'hidden'} box-7 single-services-box ${backgroundColor}`}
                                                        style={advantagesIconColor}
                                                        initial={{ opacity: 0, y: -125 }}
                                                        animate={{ opacity: 1, y: 0 }}
                                                        transition={{ duration: duration + 1.5 }}
                                                    >
                                                        <i className="bi bi-cash-coin"></i>
                                                        {altText.box7}
                                                    </motion.div>
                                                </VisibilitySensor>
                                            </Col>

                                            <Col lg={lg} sm={sm}>
                                                <VisibilitySensor
                                                    onChange={(isVisible) => {
                                                        if (!isVisible) {
                                                            setIsVisible(false);
                                                        }
                                                    }}
                                                    partialVisibility={true}
                                                    offset={{ top: 100 }}
                                                >
                                                    <motion.div
                                                        className={`${isVisible ? 'visible' : 'hidden'} box-8 single-services-box ${backgroundColor}`}
                                                        style={advantagesIconColor}
                                                        initial={{ opacity: 0, y: -125 }}
                                                        animate={{ opacity: 1, y: 0 }}
                                                        transition={{ duration: duration + 1.7 }}
                                                    >
                                                        <i className="bi bi-calendar2-day"></i>
                                                        {altText.box8}
                                                    </motion.div>
                                                </VisibilitySensor>
                                            </Col>
                                        </Row>
                                    </div>

                                    {props.page === 'clients' ?
                                        <div className="d-flex justify-content-center mt-5">
                                            <br />
                                            <CalendlyButton />
                                        </div>
                                        : <></>
                                    }
                                </>
                                :
                                <></>
                            }
                        </Row>
                    </div>
                </Container>
            </div>
        );
    }

    return (
        <VisibilitySensor
            onChange={(isVisible) => { setIsVisible(isVisible) }}
            partialVisibility={true}
            offset={{ top: 100 }}
        >
            <AdvantagesComponent />
        </VisibilitySensor>
    );
}

export default Advantages;
