
export const dealType = {
  taxOffice: 'Tax Office',
  taxAffiliate: 'Tax Affiliate',
  mentorship: 'Mentorship',
};

export function getTaxSoftwareText(
  promo,
  earlySpecialHTML,
  regularPrice,
  taxOfficeRegularPrice,
  taxAffiliateRegularPrice,
  mentorshipRegularPrice
) {

  const financingText = <div>
    <div className="d-flex justify-content-center align-items-center">
      <div className="text-start">
        <li>Our Easy Check Out:</li>
        <li>- Buy now, pay later</li>
        <li>- Financing Available</li>
        <li>- As low as 0% APR</li>
      </div>
    </div>
    <div className="fs-6 text-warning">
      <li className="mt-2">Click Affirm or AfterPay</li>
      <li>at checkout for offers</li>
    </div>
  </div>

  
return {
    taxAffiliate: 
      <>
          {promo ? 
          <> <p>{`${regularPrice} $${taxAffiliateRegularPrice}`}</p>
              {earlySpecialHTML}
          </>
          : 
          <></>
          }
          <p>1 Tax Office User ID</p>
          <p>
          <strong>70/30</strong> Split Prep-Fees
          </p>
          <p>Basic Tax Training</p>
          <p>Mentorship & Marketing Training</p>
          <p>Top Tier Tech Support</p>
          {financingText}
      </>
      ,
      taxOffice: 
      <>
        {promo ? 
        <><p>{`${regularPrice} $${taxOfficeRegularPrice}`}</p>
            {earlySpecialHTML}
        </>
        : 
        <></>
        }
        <p><strong>Unlimited</strong> Number Of Users</p>
        <p>1 Business Software</p>
        <p>Branded Taxes To Go App</p>
        <p>Mentorship & Marketing Training</p>
        <p>Top Tier Tech Support</p>
        {financingText}
      </>
      ,
      
      mentorship: 
      <>
        {promo ? 
          <><p>{`${regularPrice} $${mentorshipRegularPrice}`}</p>
          {earlySpecialHTML}
          </>
        : 
          <></>
        }
        <p>Same Options As Tax Office</p>
        {/* <p style={mentorshiptStyle}><strong>+ Added Value:</strong></p> */}
        <p><strong>+ Added Value:</strong></p>
        <p><strong>Personal</strong> Mentorship Sessions</p>
        <p><strong>Advanced</strong> Marketing</p>
        <p ><strong>Advanced</strong> Sales</p>
        {financingText}
      </>
      ,
  };
}