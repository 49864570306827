
import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Button } from "react-bootstrap";
import { PopupModal } from "react-calendly";

const CustomPopupModal = (props) => {

  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => { 
    setIsOpen(false)
    navigate('/contact');
  }

  const floatingButtonStyle = { fontWeight: 'bold', }
  return <>
    <div>
      <Button
        variant="warning"
        style={floatingButtonStyle}
        className="position-fixed bottom-0 p-2 mb-3 ms-4 rounded-pill "
        onClick={() => setIsOpen(true)}
      >
        &nbsp; I'm Ready To File! &nbsp; 
      </Button>
      <PopupModal
        url="https://taxestogo.com/App/Download/90491"
        // pageSettings={props.pageSettings}
        // utm={props.utm}
        // prefill={props.prefill}
        onModalClose={handleClose}
        open={isOpen}
        rootElement={document.getElementById("root")}
      />
    </div>
  </>
};

export default CustomPopupModal;