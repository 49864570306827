
import { useThemeContext } from "../../../hooks/usethemeContext";
import { switchTheme } from '../../../assets/styles';
import { PopupWidget } from "react-calendly";

const CalendlyPopUp = () => {
    // define style by darkMode
    const { darkMode } = useThemeContext();
    const { buttonColors } = switchTheme(darkMode);

    return <>
        <div className="hide-on-sm-screen">
            <PopupWidget
                branding
                color={darkMode ? buttonColors.teal : buttonColors.teal}
                iframeTitle="Calendly Scheduling Page"
                pageSettings={{
                    // backgroundColor: 'ffffff',
                    hideEventTypeDetails: false,
                    hideGdprBanner: true,
                    hideLandingPageDetails: false,
                    // primaryColor: '00a2ff',
                    // textColor: '4d5055'
                }}
                rootElement={document.getElementById("root")}
                text="Book An Appointment!"
                textColor={buttonColors.warning} //"#ffffff"
                url={process.env.REACT_APP_CALENDLY}
            />
        </div>
    </>;
}
 
export default CalendlyPopUp;
