
import { useState } from "react";
import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from '../../assets/styles';
import { Container, Col, Row } from "react-bootstrap";
import { motion } from "framer-motion";
import VisibilitySensor from "react-visibility-sensor";

const FunFacts = () => {
    const { darkMode } = useThemeContext();
    const { color, backgroundColor, funFactIconColor } = switchTheme(darkMode);
    const sm = 6, lg = 3, xs = 12;

    const [isVisible, setIsVisible] = useState(false);
    const duration = 1.0

    const FunFactsComponent = () => {
        return (
            <Container className='d-block justify-items-center'>
                <Row className="mx-3 align-self-center">
                    <Col xs={xs} sm={sm} lg={lg}>
                        <motion.div
                            className={`single-fact ${isVisible ? 'visible' : 'hidden'}`}
                            initial={{ opacity: 0, x: -50 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: duration + .3 }}
                            style={{ color }}
                        >
                            <i className="bi bi-hourglass-split" style={funFactIconColor}></i>
                            <h2 style={funFactIconColor}>
                                4 <span>+</span>
                            </h2>
                            <p>Years Tax In Business</p>
                        </motion.div>
                    </Col>

                    <Col xs={xs} sm={sm} lg={lg}>
                        <motion.div
                            className={`single-fact ${isVisible ? 'visible' : 'hidden'}`}
                            initial={{ opacity: 0, y: -50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: duration + .5 }}
                            style={{ color }}
                        >
                            <i className="bi bi-people" style={funFactIconColor}></i>
                            <h2 style={funFactIconColor}>
                                25 <span>+</span>
                            </h2>
                            <p>Business Partners</p>
                        </motion.div>
                    </Col>

                    <Col xs={xs} sm={sm} lg={lg}>
                        <motion.div
                            className={`single-fact ${isVisible ? 'visible' : 'hidden'}`}
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: duration + .7 }}
                            style={{ color }}
                        >
                            <i className="bi bi-person-plus" style={funFactIconColor}></i>
                            <h2 style={funFactIconColor}>
                                1100 <span>+</span>
                            </h2>
                            <p>Clients served</p>
                        </motion.div>
                    </Col>

                    <Col xs={xs} sm={sm} lg={lg}>
                        <motion.div
                            className={`single-fact ${isVisible ? 'visible' : 'hidden'}`}
                            initial={{ opacity: 0, x: 50 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: duration + .9 }}
                            style={{ color }}
                        >
                            <i className="bi bi-cash-coin" style={funFactIconColor}></i>
                            <h2 style={funFactIconColor}>
                                95 <span>%</span>
                            </h2>
                            <p>funding rate</p>
                        </motion.div>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <div className={`fun-fact-area ${backgroundColor}`}>
            <VisibilitySensor 
                onChange={(visible) => setIsVisible(visible)}
                partialVisibility={true}
                offset={{ top: 100 }}
            >
                <FunFactsComponent />
            </VisibilitySensor>
        </div>
    );
}

export default FunFacts;

