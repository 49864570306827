
import { useThemeContext } from "../../hooks/usethemeContext";
import { switchTheme } from '../../assets/styles';
import { Col, Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import about1 from '../../assets/images/about-1.webp';
// import about2 from '../../assets/images/about-2.webp';


const AboutUs = () => {
	// define style by darkMode
	const { darkMode } = useThemeContext();
	const { mainBannerTextColor } = switchTheme(darkMode);

	// const style = {color: '#0e65d7'};
	// const sm = '12', lg = '6'; // setting for 2 people about page
	const styles = { margin:  "auto", width:'23%'};
	const space = 3;

    return (
      <>
        <div className={`about-area mt-5 pb-${space}`}>
			<div className={`text-center pb-${space} border-bottom-0`}>
				<h1 className={mainBannerTextColor}>
					Who We Are!
				</h1>
			</div>
			<Container className="justify-items-center justify">
				<Row className="justify-items-center">
					<Col> {/* setting for 1 person about page */}
					{/* <Col sm={sm} lg={lg}> */} {/* setting for 2 people about page */}
						<Row>
							<div className={`pt-${space}`}>
								<img
									className={`rounded-${space} d-block mb-${space}`}
									style={styles}
									src={about1}
									alt="First slide"
								/>
								<h4 className={`text-center ${mainBannerTextColor}`}>
								Hi we are the Manion's!
								</h4>
								<p>
									Hi we are the Manion's, My husband is Greg and I'm often called Dr. Connie. We have been married for nearly 20 years and we are a blended family with nine adult children. Before I met Greg, I had suffered the loss of two mates over the years. I also struggled very badly as a single mother through the economic crisis in California.  But I managed to maintain a home while attending college. I was very blessed to have had help from family and friends. I learned the valuable lesson that accomplishments are never realized without help and encouragement from others. Finally, I landed an executive position that changed our economic status.
								</p>
								<p>
									Both my husband and I were raised in California and later moved to Texas for better opportunities. My husband is blind and became licensed to practice massage therapy and life insurance in two states. This level of dedication personally and professionally to provide for his family is the type of determination we invest in those around us to help them build their businesses.
								</p>
								<p>
									I treasure the years I have spent in the business field and value the knowledge I've gained over the years. At times the journey seemed more challenging than I could have ever imagined, and my faith brought me to a humbling place of great spirituality. With God's help, I survived the loss of two mates, raising my young children as a single parent, and finding success.  
								</p>
							</div>
						</Row>
					</Col>
					{/* <Col sm={sm} lg={lg}>
						<Row>
									<div className={`pt-${space}`}>
									<h4 className={`text-center ${mainBannerTextColor}`}>
										Jose
									</h4>
								<img
									className={`rounded-${space} d-block mb-${space}`}
									style={styles}
									src={about2}
									alt="First slide"
								/>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
								<p>
									lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
								</p>
							</div>
						</Row>
					</Col> */}
						<Container className="text-center">
							{/* <h5 className={`text-center ${mainBannerTextColor}`}>
								Our Commitment to You!
							</h5> */}
							{/* <p>
								Our goal is to change people's lives by providing the best services and opportunities to Tax Companies and Tax Preparers. We do business fairly and we don't keep your hard earned money. We provide training and tech-support for all our clients because we believe that you deserve a real opportunity.
							</p> */}
							<h5 className={`text-center ${mainBannerTextColor}`}>
								Our Commitment to You!
							</h5>
							<p>
								Our goal is to change people's lives by providing the best services and opportunities to Tax Companies and Tax Preparers. We do business fairly and we don't keep your hard earned money. We provide training and tech-support for all our clients because we believe that you deserve a real opportunity.
							</p>
					</Container>
					<NavLink
						to="/contact"
						className={`mt-${space} btn btn-outline-warning`}
						style={{maxWidth: '300px', margin: 'auto'}}
					>
						Contact us
					</NavLink>
				</Row>
          	</Container>
        </div>
      </>
    );
};

export default AboutUs;
