
import MainBanner from "./sub-components/MainBanner";
import Advantages from "./sub-components/Advantages";


export default function Home() {

  return <>
    <div>
      <section id='home-main-banner-clients' >
        <MainBanner page='clients' />
      </section>
      <section id='home-advantages-clients' >
        <Advantages page='clients' />
      </section>
    </div>
  </>;
};
