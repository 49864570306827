
import MainBanner from "./sub-components/MainBanner";
import Advantages from './sub-components/Advantages';
import Pricing from "./sub-components/TaxSoftware";
import { Container } from 'react-bootstrap'


const BusinessOpportunities = () => {


    return <>
        <div>
          <section>
            <MainBanner page='software' />
          </section>
          <section>
            <Advantages page='software' />
          </section>
          <section>
            <Pricing />
          </section>
        </div>
    </>;
};

export default BusinessOpportunities;